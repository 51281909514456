import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";
import {
  MultiProductSearchForm as SearchForm,
  SearchFormProductType,
  PackageType,
  MultiItemSearchFormInputs as SearchFormInputs,
} from "@shared-ui/retail-search-tools-product";
import { MultiProductSearchFormProps } from "./typings";
import { isVariantEnabled } from "components/utility/experiment";
import { triggerFlightsCachingWarming } from "src/utils/FlightsCacheWarmingUtil";
import { useExperiment } from "@shared-ui/experiment-context";
import { Field } from "@egds/react-core/form";
import { triggerFlightsPredictiveSearchURLVariant } from "src/utils/FlightsPredictiveSearchUtils";
import { useLodgingSearchExperiment } from "../../utility/useLodgingSearchExperiment";
import { useOptimizeLodgingSearchLoadTimeExperiment } from "../../utility/useOptimizeLodgingSearchLoadTimeExperiment";
import {
  useSRPPrefetchAssetsExperiment,
  shouldPrefetchOnFieldChange,
} from "../../utility/useSRPPrefetchAssetsExperiment";
import { PrefetchProductType } from "src/components/utility/usePrefetchAssets";

interface DefaultLobsMap {
  LODGING: SearchFormProductType;
  FLIGHTS: SearchFormProductType;
  CARS: SearchFormProductType;
  PACKAGES: SearchFormProductType;
  CRUISES: SearchFormProductType;
  ACTIVITIES: SearchFormProductType;
}

const defaultLobsMap: DefaultLobsMap = {
  LODGING: SearchFormProductType.LODGING,
  FLIGHTS: SearchFormProductType.FLIGHTS,
  CARS: SearchFormProductType.CARS,
  PACKAGES: SearchFormProductType.PACKAGES,
  CRUISES: SearchFormProductType.CRUISES,
  ACTIVITIES: SearchFormProductType.ACTIVITIES,
};

export const MultiProductSearchForm = withStores(
  "context",
  "analytics",
  "page"
)(
  observer((props: MultiProductSearchFormProps) => {
    const { templateComponent, context, page } = props;
    const [flightsTabClicked, setFlightsTabClicked] = React.useState(false);
    const lodgingSearchExperimentProps = useLodgingSearchExperiment();
    const { prefetchSRPAssets: prefetchLodgingSRPAssets } = useSRPPrefetchAssetsExperiment(
      PrefetchProductType.LODGING_SRP
    );
    const { prefetchSRPAssets: prefetchFlightSRPAssets } = useSRPPrefetchAssetsExperiment(
      PrefetchProductType.FLIGHTS_SRP
    );

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const initialLobSelected = templateComponent?.config.initialLobSelected;
    const marginOnTop = templateComponent?.config.marginOnTop ?? true;
    const sticky = templateComponent?.config.sticky ?? false;
    const locationType = context?.searchContext?.location?.type;
    const nonSupportedLocationTypes = ["continent", "country", "province_state"];

    /* istanbul ignore next */
    const handleProductSelect = (productType: SearchFormProductType) => {
      if (
        productType === SearchFormProductType.FLIGHTS &&
        !flightsTabClicked &&
        isVariantEnabled(context, "Air_Search_Performance_V2_Web_FSR_Prefetch_Recent_Searches_MVP_Test", 1)
      ) {
        setFlightsTabClicked(true);
        triggerFlightsCachingWarming(context);
      }
    };

    const commonProps = {
      updateLatLongOnSelection: undefined,
      onOpenDatesField: undefined,
      onOpenLocationField: undefined,
    };

    const { extendedName, id: destinationRegionId } = context?.searchContext?.location || {};

    const packageInputs: SearchFormInputs = {
      packageSearchCriteria: {
        primary: !destinationRegionId
          ? {}
          : {
              destination: {
                airportCode: "",
                regionId: "" + destinationRegionId,
                regionName: extendedName,
              },
              dateRange: {
                start: {
                  day: 1,
                  month: 1,
                  year: 1970,
                },
                end: {
                  day: 1,
                  month: 1,
                  year: 1970,
                },
              },
            },
      },
      includePackageType: true,
      packageType: PackageType.FLIGHT_HOTEL,
    };

    /* istanbul ignore next */
    const commonAdditionalESSAdapterProps = { xPageId: page?.pageId ?? "" };

    const initialSelectedProduct: SearchFormProductType =
      defaultLobsMap[(initialLobSelected as keyof DefaultLobsMap) ?? "LODGING"] ?? SearchFormProductType.LODGING;

    const { exposure: preemptiveSearchExposureFSR } = useExperiment("FSR_PREEMPTIVE_SEARCH");

    /* istanbul ignore next */
    const handleFieldChange = (field: Field, event: React.SyntheticEvent, queryParams: string) => {
      const isFlightsPredictiveSearch = preemptiveSearchExposureFSR.bucket === 1;
      const isDateChanged =
        field?.name === "EGDSDateRangePicker" ||
        field?.name === "EGDSSingleDatePicker" ||
        field?.name === "EGDSSingleDatePicker-StartDate" ||
        field?.name === "FlightsFareCalendar-ROUND_TRIP" ||
        field?.name === "FlightsFareCalendar-ONE_WAY";
      triggerFlightsPredictiveSearchURLVariant(isFlightsPredictiveSearch, isDateChanged, queryParams);
      if (prefetchFlightSRPAssets && shouldPrefetchOnFieldChange(PrefetchProductType.FLIGHTS_SRP, field)) {
        prefetchFlightSRPAssets(field);
      }
    };

    const { triggerPreloadingSearch } = useOptimizeLodgingSearchLoadTimeExperiment();

    const handleLodgingFieldChange = React.useCallback(
      (field: Field, event: React.SyntheticEvent, queryParams: string) => {
        triggerPreloadingSearch(field, event, queryParams);
        prefetchLodgingSRPAssets(field);
      },
      [prefetchLodgingSRPAssets, triggerPreloadingSearch]
    );

    /* istanbul ignore next */
    return (
      <div id={id} data-testid="multi-product-search-form">
        <UitkSpacing margin={marginOnTop ? { medium: { blockstart: "twelve" } } : undefined}>
          <div>
            <SearchForm
              sticky={sticky}
              batchKey="multi-product-search-form"
              initialSelectedProduct={initialSelectedProduct}
              onProductTypeSelection={handleProductSelect}
              searchFormOptionsByLob={{
                activities: { ...commonProps, additionalAdapterFields: { ...commonAdditionalESSAdapterProps } },
                cars: {
                  ...commonProps,
                  additionalAdapterFields: { ...commonAdditionalESSAdapterProps },
                },
                cruises: { ...commonProps, additionalAdapterFields: { ...commonAdditionalESSAdapterProps } },
                flights: {
                  ...commonProps,
                  inputs: {},
                  onFieldChange: handleFieldChange,
                  additionalAdapterFields: { ...commonAdditionalESSAdapterProps },
                },
                lodging: {
                  ...commonProps,
                  ...lodgingSearchExperimentProps,
                  enableReflection: true,
                  additionalAdapterFields: {
                    ...commonAdditionalESSAdapterProps,
                    historyDetail: true,
                    popularFilter: true,
                  },
                  onFieldChange: handleLodgingFieldChange,
                },
                packages: {
                  ...commonProps,
                  inputs: nonSupportedLocationTypes.includes(locationType) ? "" : { ...packageInputs },
                  additionalAdapterFields: {
                    ...commonAdditionalESSAdapterProps,
                    trending: true,
                  },
                },
              }}
            />
          </div>
        </UitkSpacing>
      </div>
    );
  })
);

MultiProductSearchForm.displayName = "MultiProductSearchForm";

export default MultiProductSearchForm;
